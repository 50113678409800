<template>
  <!-- <i-icon name="icon-magnify" v-if="!isSearch" v-ripple></i-icon> -->
  <!-- <el-button v-if="!isSearch" icon="el-icon-search"></el-button> -->
  <div class="h-wrapper">
    <el-popover
      :value="visible"
      :visible-arrow="false"
      ref="popover"
      placement="bottom-start"
      title=""
      popper-class="el-popper--isnp el-popper--isnm el-popper--isnb"
      :offset="offset"
      :trigger="trigger">
      <div class="h-results" @click.stop.prevent>

        <div class="above">
        <el-input v-show="inputVisible" v-model.trim="queryKey" size="mini" slot="reference" @keydown.enter.native="enterHandle" @focus="isFocus=true" @blur="isFocus=false" autofocus ref="input" @keydown.down.native="nextItem"
        @keydown.up.native="preItem"
        :placeholder="$t('commonTreeUser.keyTip')" clearable
        :class="{'animate': loading, 'is-focus': isFocus}">
          <i-icon class="prifixIcon" name="icon-magnify" v-if="!isSearch" slot="suffix" :class="{'animate': loading}"></i-icon>
        </el-input>
        </div>

        <!-- {{results}} -->
        <div key="1" v-if="!queryKey" class="no-results">{{$t('commonTreeUser.pleaseInputKey')}}</div>
        <div key="2" v-else-if="loading" class="no-results loading">
          <circle2 size="20px"></circle2>
          <p>{{$t('commonTreeUser.loading')}}</p>
        </div>
        <div key="3" v-else-if="!results.length" class="no-results">{{$t('common.noResults')}}</div>
        <div key="4" class="h-results-vertical" v-if="queryKey">
          <div v-for="(group, gIndex) in results" :key="group.group" class="h-group">
            <p class="h-group-title">{{$t('layout')[group.group] || group.group}}</p>
            <div class="h-group-content" v-for="(subgroup, p) in group.items" :key="p">
              <div class="content-label" :class="{ [`type${subgroup.type}`]: true, 'hover': isCurentIndex(gIndex, p) }" @click="itemClick(subgroup)">
                <!--用户名、车辆名-->
                <p v-if="subgroup.type == 2" @mouseover="showTitle" class="type2">
                  <span>
                    <i-icon name="icon-account" class="icon-account"></i-icon>
                    <highlight-key :light-key="queryKey" :text="subgroup.subgroup"></highlight-key>
                  </span>

                  <span class="tip">{{subgroup.desc}}</span>
                </p>

                <p v-else-if="subgroup.type == 4" @mouseover="showTitle">
                  <highlight-key :light-key="queryKey" :text="subgroup.subgroup"></highlight-key>
                </p>


                <p v-else @mouseover="showTitle">{{subgroup.subgroup}}</p>
              </div>
              <ul class="content-list" v-if="subgroup.type != 2">
                <li v-for="(item,index) in subgroup.items" :key="index" @click="itemClick(item, subgroup)">
                  <i-icon name="icon-crosshairs-gps" class="imr-0" v-if="item.ext.TrackerType==0"></i-icon>
                  <i-icon name="icon-GPS_1" class="imr-0" v-else></i-icon>
                  <highlight-key :light-key="queryKey" :text="item.name"></highlight-key>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <slot slot="reference"></slot>
    </el-popover>
  </div>
</template>

<script>
import {debounce} from '@/common/utils'
import {AdvanceSearchFK} from '@/api/common'
import HighlightKey from '@/views/common/highlight-key'
import { Circle as Circle2 } from 'vue-loading-spinner'

export default {
  props: {
    menus: {
      type: Array,
      default: () => ([])
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    inputVisible: {
      type: Boolean,
      default: true
    },
    searchKey: {
      type: String,
      default: ''
    },
    offset: {
      type: Number,
      default: 22
    },
    trigger: {
      type: String,
      default: 'manual'
    }
  },
  data() {
    return {
      isFocus: false,
      isSearch: false,
      queryKey: '',
      results: [],
      loading: false,
      activeIndex: null
    }
  },
  watch: {
    searchKey(val) {
      this.queryKey = val
    },
    visible(val) {
      if (val) {
        window.addEventListener('click', this.closeMenu)
        this.$nextTick(this.focusThisInput)
        this.activeIndex = null
      } else {
        window.removeEventListener('click', this.closeMenu)
      }
    },
    queryKey() {
      this.debounce_fetchResult()
    }
  },
  components: {
    HighlightKey,
    Circle2
  },
  computed: {
    menuList() {
      let output = []
      this.menus.forEach(k => {
        if (k.link) {
          // 可点击, 无子节点
          output.push(Object.assign({}, k, {items: [k]}))
        } else {
          output.push(Object.assign({}, k))
        }
      })
      return output
    },
    maxLength() {
      let total = 0
      for (let i = 0; i < this.results.length; i++) {
        total += this.results[i].items.length
      }
      return total
    }
  },
  mounted() {
    this.debounce_fetchResult = debounce(this.fetchResult, 300)
  },
  methods: {
    fetchResult() {
      // let result = {
      //   group: '功能',
      //   items: [
      //     {
      //       subgroup: '首页',
      //       value: '',
      //       type: 1, // 1 路由 2 用户 3 账号 4 车辆 5 设备
      //       items: [
      //         {
      //           name: '',
      //           value: '',
      //           type: 1 // 1 路由 2 用户 3 账号 4 车辆 5 设备
      //         }
      //       ]
      //     }
      //   ]
      // }

      // console.log('fetchResult')

      if (!this.queryKey) {
        // result置空
        this.results = []
        return
      }


      this.loading = true
      AdvanceSearchFK(this.queryKey, true).then(ret => {
        this.activeIndex = null
        let results = ret.data.outList

        // 屏蔽功能查找
        // let menuGroup = this.filterMenu(this.queryKey)
        let menuGroup = { group: '功能', items: [] }

        if (menuGroup.items.length) {
          results.splice(0, 0, menuGroup)
        }

        this.results = results
        // console.log(this.results)
      }).finally(() => {
        this.loading = false
      })

      // this.results = [
      //   menuGroup
      // ]


    },

    filterMenu(key) {
      let output = []
      this.menuList.forEach(k => {
        let j = {
          items: []
        }

        k.items.forEach(p => {
          if (p.title.toLowerCase().indexOf(key.toLowerCase()) != -1) {

            // 添加路由
            j.items.push({
              name: p.title,
              value: p.link,
              type: 1
            })

          }
        })

        if (j.items.length > 0) {
          output.push(Object.assign({
            subgroup: k.title,
            value: k.link,
            type: 1
          }, j))
        }
      })

      return { group: '功能', items: output }
    },

    itemClick(item, v) {
      if (item.type == 1) {
        // this.$router.push(item.value)
      } else if (item.type == 2) {
        // this.$refs.detailHold.open(item.value)
        this.$emit('select-hold', { holdId: item.value, value: item.subgroup })
        this.closeMenu()
      } else if (item.type == 3) {
        // this.$refs.detailUser.open(item.value)
      } else if (item.type == 4) {

        //this.$refs.detailVehicle.open(item.value, {tabName: 'vehicle'})

        if (!item.value) {
          this.$message(this.$t('commonListVehicle.noVehicle'))
        } else if (item.items && item.items.length > 0) {
          // 监控车辆
          // this.$router.replace(`/online/monitor?action=addTaskList&objectid=${item.items.map(k => k.value).join(',')}`)
          let data = item.items.map(k => k.value)
          this.$emit('click-batch', data)
          this.closeMenu()
        } else {
          // this.$refs.detailVehicle.open(item.value, {tabName: 'vehicle'})
          this.$message(this.$t('commonListVehicle.noDevice'))
        }
      } else if (item.type == 5) {
        // this.$refs.detailDevice.open(item.value)
        // this.$router.replace(`/online/monitor?action=addTaskList&objectid=${item.value}`)
        this.$emit('click-device', v.value, item.value)
        this.closeMenu()
      }
    },

    showTitle(e) {
      if (e.target.offsetWidth < e.target.scrollWidth) {
        e.target.title = e.target.innerText
      } else {
        e.target.removeAttribute('title')
      }
    },

    closeMenu() {
      this.$emit('update:visible', false)
    },

    focusThisInput() {
      if (this.$refs.input) {
        this.$refs.input.focus()
        this.$refs.input.select()
      }
    },

    isCurentIndex(gIndex, p) {
      if (gIndex == 0) {
        return p == this.activeIndex
      } else if (gIndex == 1) {
        return p + this.results[0].items.length == this.activeIndex
      }
    },

    enterHandle() {
      if (this.activeIndex !== null) {
        if (!this.results.length) {
          return
        } else if (this.activeIndex <= this.results[0].items.length) {
          this.itemClick(this.results[0].items[this.activeIndex])
        } else {
          this.itemClick(this.results[1].items[this.activeIndex - this.results[0].items.length])
        }

      } else {
        this.fetchResult()
      }
    },

    nextItem() {
      if (!this.results.length) {
        return
      }

      if (this.activeIndex === null) {
        this.activeIndex = 0
      } else if (this.activeIndex == this.maxLength - 1) {
        this.activeIndex = 0
      } else {
        this.activeIndex++
      }
    },

    preItem() {
      if (!this.results.length) {
        return
      }

      if (this.activeIndex === null) {
        this.activeIndex = this.maxLength - 1
      } else if (this.activeIndex === 0) {
        this.activeIndex = this.maxLength -1
      } else {
        this.activeIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
@import "../../styles/mixin.scss";
.h-wrapper {
  // height: $page-header-height;
  width: 215px;
  transition: all 0.3s ease-in-out;
  will-change: width;
  margin: 0 8px;
  // &.focus {
  //    width: 325px;
  // }
  & ::v-deep .el-popover {
    margin-top: 22px;
  }

}
$lineHeight: 28px;
$highLightColor: #2C22F3;
.h-results {
  min-width: 425px;
  max-width: 90vw;
  border-radius: 0;
  padding: 0;
  padding: 12px 12px 8px 12px;
  max-height: calc(100vh - #{$page-header-height} * 2 );
  overflow-y: auto;
  // border: 1px solid #eee;
  box-shadow: 0 1px 3px 0 rgba($highLightColor, .25);
  @include scroll-bar;
  .h-group {
    margin-top: 12px;
    font-size: 12px;
    .h-group-title {
      // background-color: #FFF;
      color: black;
      height: $lineHeight;
      line-height: $lineHeight;
      padding: 0 10px;
      text-align: left;
      font-weight: 600;
      background-color: #F4F4F8;
      margin-bottom: 4px;
    }
    .h-group-content {
      display: flex;
      align-items: stretch;
      // border-bottom: 1px solid #d3d3d3;
      background-color: #F4F4F8;
      margin-bottom: 4px;

      &:last-child {
        border-bottom: 0;
      }

      .content-label {
        width: 200px;
        flex: 0 0 200px;
        // background-color: #F1F3F5;
        color: #555;
        text-align: right;
        line-height: $lineHeight;
        padding: 0 10px;
        border-right: 1px solid #EAEAEA;
        &:hover, &.hover {
          cursor: pointer;
          background-color: #E7EDF3;
          // background-color: #FAFAFA;
        }

        p {
          height: $lineHeight;
          line-height: $lineHeight;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

        }

        &.type2 {
          width: auto;
          flex: 1 1 auto;
          text-align: left;
          border-right: 0;
        }

        .tip {
          color: rgba(0, 0, 0, .26);

        }

        p.type2 {
          display: flex;
          justify-content: space-between;
          span:first-child {
            flex: 0 0 auto;
            padding-right: 12px;
            .icon {
              margin-right: 4px;
            }
          }
          span:last-child {
            flex: 1 1 auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: right;
          }
        }
      }
      .content-list {
        list-style: none;
        flex: 1 1;
        li {
          border-bottom: 1px solid #EAEAEA;
          height: $lineHeight;
          line-height: $lineHeight;
          padding: 0 10px;
          display: block;
          color: #2c3e50;

          &:last-child {
            border-bottom: 0;
          }

          &:hover {
            cursor: pointer;
            background-color: #E7EDF3;
          }
        }
        // & li:last-child {
        //   border-bottom: 0;
        // }
      }
    }
  }
  .no-results {
    min-height: $lineHeight * 2;
    // line-height: $lineHeight * 2;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 12px 0;
    &:not(.loading) {
      opacity: .26;
    }
    &.loading {
      opacity: .56;
    }

    p {
      // margin-top: 8px;
      margin-left: 12px;
    }
  }

  .el-input {
    & ::v-deep  {
      .el-input__inner {
        border-radius: 14px;
        background-color: transparent;
        color: rgba(0, 0, 0, .84);
        border-color: #EEE;
        transition: all 0.3s ease-in-out;
        &:focus {
          box-shadow: 0 1px 3px 0 rgba($highLightColor, .25);
          border-color: transparent;
          background-color: #F4F4F8;
        }
      }
      .prifixIcon {
        font-size: 20px;
        vertical-align: middle;
        margin: 0 4px;
        height: 100%;
        transition: all 0.3s ease-in-out;
        &.animate {
          animation: magnify 2s linear infinite;
          color: $--color-primary;
        }
      }
    }
    &.is-focus {
      .prifixIcon {
        color: $highLightColor;
      }
    }
  }

  .above {
    position: sticky;
    top: 0;
    left: 0;
  }
}

@keyframes magnify {
  0% {
    transform: translateX(-4px);
  }
  12% {
    transform: translate3d(-2px, -2px, 0);
  }
  25% {
    transform: translateY(-4px);
  }
  37% {
    transform: translate3d(2px, -2px, 0);
  }
  50% {
    transform: translateX(4px);
  }
  62% {
    transform: translate3d(-2px, -2px, 0);
  }
  75% {
    transform: translateY(2px);
  }
  87% {
    transform: translate3d(-2px, 2px, 0);
  }
  100% {
    transform: translateX(-4px);
  }
}

@keyframes raceLamp {
  0% {
    width: 0;
    transform: translateX(0);
  }
  25% {
    width: 50%;
    transform: translateX(50%);
  }
  50% {
    width: 0;
    transform: translateX(100%);
  }
  75% {
    width: 50%;
    transform: translateX(50%);
  }
  100% {
    width: 0;
    transform: translateX(0);
  }
}
</style>
